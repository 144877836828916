<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card class="pa-4" :disabled="loading" :loading="loading">
      <v-card-title
        >ポストプレビュー
        <v-btn class="ml-auto" text color="blue" :to="{ name: 'Post' }"
          >戻る</v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <div v-if="post" class="mx-auto" style="max-width: 1000px;">
        <v-card-title class="flex-wrap">
          <div>{{ post.title }}</div>
        </v-card-title>
        <div class="d-flex flex-column align-end">
          <span class="my-auto px-4">{{ post.created_date }}</span>
          <div class="d-flex px-4 mt-3">
            <v-btn
              text
              v-for="cat in post.categories"
              :key="cat.id"
              :to="{ name: '', exact: true }"
              class="mx-1"
              >{{ cat.name }}</v-btn
            >
          </div>
        </div>
        <v-card-text>
          <img :src="post.featured_image" width="100%" alt="" />
        </v-card-text>
        <v-card-title>{{ post.description }}</v-card-title>
        <div v-html="post.content" class="px-4"></div>

        <div class="post-tags-blk px-4">
          <ul>
            <li v-for="tag in post.tags" :key="tag.id">
              <a href="javascript:void(0);">{{ tag.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import api from '@/services/api-methods'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: '施工事例管理',
          disabled: false,
          to: { name: 'Post' },
          exact: true
        },
        {
          text: 'Preview',
          disabled: false,
          to: { name: '' },
          exact: true
        }
      ],
      post: [],
      loading: false,
      post_categories: [],
      post_tags: []
    }
  },
  methods: {
    getPost(slug) {
      this.loading = true
      api
        .get('/posts/' + slug)
        .then(response => {
          this.loading = false
          this.post = response.data.data
          this.post.category = this.post.category
            .split(',')
            .map(n => parseInt(n))
          this.post.tag = this.post.tag.split(',').map(n => parseInt(n))
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  mounted() {
    this.getPost(this.$route.params.id)
  }
}
</script>

<style lang="css" scoped>
.post-tags-blk {
  margin: 0 0 20px;
}
.post-tags-blk ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;
  margin-bottom: 0px;
  padding: 0;
}
.post-tags-blk ul li {
  list-style-type: none;
  padding: 0 6px;
  font-size: 16px;
  color: #333;
  line-height: 20px;
}
.post-tags-blk ul li a {
  border: 1px solid #3ba3ee;
  background: #fff;
  border-radius: 4px;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #3ba3ee;
  display: inline-block;
  padding: 4px 16px;
  margin: 0 0 4px;
}
</style>
